
import { Component, Mixins } from 'vue-property-decorator';
import { inject } from '@/inversify';

import DragScrollMixin from '@/modules/common/mixins/drag-scroll.mixin';
import ClipText from '@/modules/common/filters/clip-text.filter';
import type HotelsService from '@/modules/hotels/hotels.service';
import type ProvidersService from '@/modules/providers/providers.service';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

import PromotionsMark from './promotions-mark.vue';
import PromotionsCalendar from './promotions-calendar-tooltip.vue';
import PromotionsPercentTooltip from './promotions-percent-tooltip.vue';
import type PromotionsService from '../promotions.service';

@Component({
    components: {
        PromotionsCalendar,
        PromotionsMark,
        PromotionsPercentTooltip,
        ProviderCard,
    },
})
export default class PromotionsMarkTable extends Mixins(DragScrollMixin) {
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.PromotionsService) private promotionsService!: PromotionsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipPercentFocusElement: HTMLElement | null = null;
    private unsubFromFilters: () => void = () => {};

    mounted() {
        const { container } = this.$refs as {
            [k: string]: any;
        };

        const resetTooltips = () => {
            this.resetFocusElement();
            this.resetPercentTooltipElement();
        };

        container.addEventListener('scroll', resetTooltips);

        this.unsubFromFilters = this.promotionsService
            .onFiltersChanged(resetTooltips);
    }

    beforeDestroy() {
        this.unsubFromFilters();
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId);
    }

    get competitors() {
        if (this.isLoading) {
            // NOTE: Create some fake data to show skeleton
            return Array
                .from({ length: 5 })
                .map(_ => this.currentHotelId);
        }

        this.resetFocusElement();
        const { competitors } = this.documentFiltersService;
        return competitors!;
    }

    get isLoading() {
        return (this.promotionsService.isLoading);
    }

    get skeleton() {
        return this.isLoading;
    }

    get providerList() {
        if (this.isLoading) return ['loading'];
        if (!this.promotionsService.data) return [];

        return this.promotionsService.providers;
    }

    get programList() {
        if (this.isLoading) {
            return ({
                loading: [
                    '_________________',
                    '________________',
                    '_______________',
                    '______________',
                    '__________________',
                ],
            });
        }

        if (!this.promotionsService.data) return {};
        return this.promotionsService.data.promotions;
    }

    cutString(s: string = '') {
        return ClipText(s, 23);
    }

    getHotelName(hotelId: number, full: boolean = false) {
        if (full) {
            return this.hotelsService.hotelNames[hotelId];
        }

        return this.cutString(this.hotelsService.hotelNames[hotelId]);
    }

    setFocusElement(e: { currentTarget: HTMLElement }) {
        const { currentTarget } = e;

        this.tooltipFocusElement = currentTarget;
    }

    resetFocusElement() {
        this.tooltipFocusElement = null;
    }

    setPercentTooltipElement(element: HTMLElement) {
        this.tooltipPercentFocusElement = element;
    }

    resetPercentTooltipElement() {
        this.tooltipPercentFocusElement = null;
    }

    getProviderName(provider: string) {
        return this.providersService.getProviderLabel(provider);
    }

    getProgramLabel(program: string) {
        return this.$tc(`promotions.program.${program}`);
    }

    getProgramLogo(program: string) {
        return this.promotionsService.getProgramLogo(program);
    }

    getProgramList(provider: string) {
        return this.promotionsService.getProgramList(provider, this.currentHotelId);
    }
}
